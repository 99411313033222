import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import * as React from "react"
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useIntl } from "gatsby-plugin-intl"
import { navigate } from "gatsby";

export default function StickyFooter() {
  const intl = useIntl()

  function navigateTo(url) {
    navigate(url)
  }

  return (
    <footer className="footer">
      <Box>
        <Container maxWidth="lg">
          <Grid container spacing={5}>

            <Grid item xs={12} sm={6} md={6}>
              <Box style={{textAlign: "center"}}>{intl.formatMessage({ id: "socialNetwork" })}</Box>
              <hr style={{textAlign: "center", width: "40%"}}/>
              <Box style={{textAlign: "center"}}>
                <FacebookIcon onClick={() => {navigateTo("https://www.facebook.com/Flipidii")}} className="custom" style={{marginRight: "2em"}} fontSize="large" />
                <TwitterIcon onClick={() => {navigateTo("https://twitter.com/Flipidii")}} className="custom" fontSize="large" />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6} style={{textAlign: "center"}}>
              <div style={{paddingTop: "5%"}}>
                © {new Date().getFullYear()}, Flipidi
              </div>
            </Grid>
          </Grid>

        </Container>
      </Box>
    </footer>
  )
}
