import * as React from "react"
import StickyFooter from "./stickyfooter"
import Header from "./header"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div>
      <Header></Header>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <main id="body">{children}</main>
      </div>
      <StickyFooter></StickyFooter>
    </div>

  )
}

export default Layout
