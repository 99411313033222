import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import * as React from "react"
import { navigate } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

export default function Header() {
  function navigateTo(url) {
    navigate(url)
  }
  const intl = useIntl()

  return (
    <header className="header">
      <Box>
        <Container maxWidth="lg">
          <Grid container spacing={5}>

            <Grid item xs={12} sm={6} md={6}>
              <Box className="headerTitle">
                <span className="flipidi-title" onClick={() => {navigateTo("/")}}>Flipidi</span>
                <span className="menu-item" onClick={() => {navigateTo("/cine")}}>{intl.formatMessage({id: "movies"})}</span>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6} style={{textAlign: "center"}}>
              <label>{intl.formatMessage({id: "language"})}: </label>
              <img src={'/mexico.png'}
                   height="50"
                   className="flags"
                   onClick={() => {navigateTo("/es")}}
                   alt="mexico" />
              <img src={'/usa.png'}
                   height="50"
                   className="flags"
                   onClick={() => {navigateTo("/en")}}
                   alt="mexico" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </header>
  )
}


/*
import * as React from 'react';


const Header = () => {

  return (
    <h1>Header</h1>
  );
}
export default Header
*/
